import apiClient from '..';
import { setLoading } from '@/plugins/loading';
export const fetchEmailBlackListFromAPI = async params => {
    try {
        const response = await apiClient.get('/configs/email-blacklist', {
            params,
        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const fetchConfig = async () => {
    try {
        setLoading(true);
        const response = await apiClient.get(
            '/configs/email-blacklist/configs'
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    } finally {
        setLoading(false);
    }
};

export const deleteEmailBlackListFromAPI = async id => {
    try {
        const response = await apiClient.delete(
            `/configs/email-blacklist/${id}`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
