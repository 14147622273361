<template>
    <div class="d-flex align-items-center justify-content-center vh-100">
        <div class="card p-4" style="width: 100%; max-width: 900px">
            <div v-if="loading">Loading...</div>
            <div v-else>
                <div class="card-header text-center mb-4">
                    <h2 v-if="success">
                        {{
                            $t(
                                'users.users_title__user_verification_successful'
                            )
                        }}
                    </h2>
                    <h2 v-else>
                        {{ $t('users.users_title__user_verification_failed') }}
                    </h2>
                </div>
                <div class="card-body">
                    <p>{{ message }}</p>
                    <button
                        type="button"
                        class="btn btn-primary w-50"
                        v-if="redirecting"
                        @click="handleOkClick"
                    >
                        {{ $t('users.users_msg__verification_button') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, inject } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import i18n from '@/api/i18n';
import { useRouter } from 'vue-router';
import { verifyUserVerificationToken, verifyUser } from '@/api/users';
import apiClient from '@/api/index';

export default {
    props: {
        token: {
            Type: String,
            required: true,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const router = useRouter();
        const store = useStore();
        const Swal = inject('$swal');

        const loading = ref(true);
        const success = ref(false);
        const message = ref('');
        const redirecting = ref(false);
        const require_new_password = ref(false);

        onMounted(async () => {
            const locale = localStorage.getItem('locale') || 'en';
            await store.dispatch('i18n/loadLocale', locale);

            const responseLang = await apiClient.get(
                `/languages/lang/${locale}?disable_cache=false`
            );
            i18n.global.setLocaleMessage(locale, responseLang.data);
            i18n.global.locale.value = locale;
            try {
                const response = await verifyUserVerificationToken(props.token);
                if (response.data.valid) {
                    success.value = true;
                    require_new_password.value =
                        response.data.require_new_password;
                    if (require_new_password.value) {
                        message.value = t(
                            'users.users_msg__user_require_change_password_to_verify'
                        );
                    } else {
                        message.value = t(
                            'users.users_msg__user_verification_successful'
                        );
                    }
                } else {
                    success.value = false;
                    message.value = t(
                        'users.users_msg__user_verification_failed'
                    );
                }
            } catch (error) {
                success.value = false;
                message.value = t('users.users_msg__user_verification_failed');
            } finally {
                redirecting.value = true;
                loading.value = false;
            }
        });

        const handleOkClick = async () => {
            if (require_new_password.value) {
                router.push({
                    path: `/verify-user/${props.token}`,
                });
            } else {
                if (success.value) {
                    try {
                        const response = await verifyUser(props.token);
                        await Swal.fire({
                            title: t(
                                'users.users_title__user_verification_successful'
                            ),
                            text: t(`users.${response.data.message}`),
                            icon: 'success',
                            showConfirmButton: true,
                        });
                        router.push({
                            path: '/',
                        });
                    } catch (error) {
                        await Swal.fire({
                            title: t(
                                'users.users_title__user_verification_failed'
                            ),
                            text: t(`users.${error.response.data.error}`),
                            icon: 'error',
                            showConfirmButton: true,
                        });
                    }
                } else {
                    router.push({
                        path: '/',
                    });
                }
            }
        };

        return {
            loading,
            success,
            message,
            redirecting,
            handleOkClick,
            require_new_password,
        };
    },
};
</script>

<style scoped>
button {
    margin-top: 20px;
}
</style>
