<template>
    <div class="form-group">
        <label v-if="field_name" class="control-label" :for="field">
            {{ translatedFieldName }}
        </label>

        <input
            v-if="type !== 'select'"
            :id="field"
            :type="type"
            v-model="modelValue"
            :placeholder="translatedPlaceholder"
            class="form-control"
            @blur="checkValue"
            :required="required === 'true'"
            :aria-describedby="addonAria"
            :aria-invalid="errorMessage"
            :readonly="type === 'readonly'"
        />

        <select
            v-if="type === 'select'"
            :id="field"
            v-model="modelValue"
            class="form-control"
            @blur="checkValue"
            :required="required"
            :aria-describedby="addonAria"
            :aria-invalid="!!errorMessage"
        >
            <option value="" disabled>{{ translatedOptionTitle }}</option>
            <option v-for="option in options" :key="option" :value="option">
                {{ option }}
            </option>
        </select>

        <small v-if="errorMessage" class="text-danger">{{
            errorMessage
        }}</small>
        <small v-if="successMessage" class="text-success">{{
            successMessage
        }}</small>
    </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { checkInput } from '@/api/users';

export default {
    name: 'UserFormGroupInput',
    props: {
        field: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: 'text',
        },
        value: {
            type: [String, Number],
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        field_name: {
            type: String,
            default: '',
        },
        check_url: {
            type: String,
            default: '',
        },
        required: {
            type: String,
            default: 'false',
        },
        options: {
            type: Array,
            default: () => [],
        },
        option_title: {
            type: String,
            default: '',
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const modelValue = ref(props.value);
        const errorMessage = ref('');
        const successMessage = ref('');

        const translatedFieldName = computed(() => {
            return t(`users.${props.field_name}`);
        });

        const translatedPlaceholder = computed(() => {
            return t(`users.${props.placeholder}`);
        });

        const translatedOptionTitle = computed(() => {
            return t(`users.${props.option_title}`);
        });

        const addonAria = computed(() => {
            return `${props.field}-addon`;
        });

        watch(modelValue, newValue => {
            emit('update:modelValue', newValue);
        });

        const checkValue = async () => {
            if (props.check_url) {
                try {
                    const response = await checkInput(
                        props.check_url,
                        modelValue.value
                    );
                    if (response.error) {
                        errorMessage.value = t('users.' + response.error);
                        successMessage.value = '';
                        emit('valid', false);
                    } else {
                        successMessage.value = t('users.' + response.message);
                        errorMessage.value = '';
                        emit('valid', true);
                    }
                } catch (error) {
                    if (error.response.status === 400) {
                        errorMessage.value = t(
                            'users.' + error.response.data.error
                        );
                        successMessage.value = '';
                        emit('valid', false);
                    }
                }
            }
        };

        return {
            modelValue,
            translatedFieldName,
            translatedPlaceholder,
            translatedOptionTitle,
            checkValue,
            errorMessage,
            successMessage,
            addonAria,
        };
    },
};
</script>

<style>
.text-danger {
    color: #e3342f;
}
.text-success {
    color: #38c172;
}
</style>
