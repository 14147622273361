<template>
    <div id="app">
        <LoadingOverlay :visible="isLoading" />
        <router-view v-slot="{ Component }">
            <transition name="fade">
                <component :is="Component" />
            </transition>
        </router-view>
    </div>
</template>

<script>
import { onMounted } from 'vue';
import LoadingOverlay from './components/Loading/LoadingOverlay.vue';
import { isLoading } from './plugins/loading';
import { useStore } from 'vuex';

export default {
    name: 'App',
    components: {
        LoadingOverlay,
    },
    setup() {
        const store = useStore();

        onMounted(async () => {
            const navigationEntries =
                performance.getEntriesByType('navigation');
            if (navigationEntries.length > 0) {
                const navigationEntry = navigationEntries[0];

                // check if the navigation entry is a reload
                if (navigationEntry.type === 'reload') {
                    // if it is, force clear the cache and fetch tenant info
                    await store.dispatch('system/fetchTenantInfoClearCache');
                } else {
                    // if not a reload, fetch tenant info
                    await store.dispatch('system/fetchTenantInfo');
                }
            } else {
                // if no navigation entries, fetch tenant info
                await store.dispatch('system/fetchTenantInfo');
            }
        });

        return {
            isLoading,
        };
    },
};
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    display: flex;
    flex-direction: column;
}
</style>
