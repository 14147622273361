<template>
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">
                {{ $t('sidebar.sidebar_msg_edit_a_user') }}
            </h4>
        </div>
        <form class="card-body" @submit.prevent="handleSubmit">
            <div v-for="(inputGroup, index) in formConfig" :key="index">
                <!-- 單一物件的情況 -->
                <div v-if="!Array.isArray(inputGroup)" class="form-group-row">
                    <UserFormGroupInput
                        v-bind="inputGroup"
                        v-model="formData[inputGroup.field]"
                        @valid="handleValidation"
                    />
                </div>
                <!-- 多個物件（數組）的情況 -->
                <div v-else class="form-group-row d-flex">
                    <div
                        v-for="(inputConfig, subIndex) in inputGroup"
                        :key="subIndex"
                        class="flex-fill"
                    >
                        <UserFormGroupInput
                            v-bind="inputConfig"
                            v-model="formData[inputConfig.field]"
                            @valid="handleValidation"
                        />
                    </div>
                </div>
            </div>
            <button
                type="submit"
                class="btn btn-primary"
                :disabled="submitDisabled"
            >
                Submit
            </button>
            <button type="reset" class="btn btn-secondary" @click="handleReset">
                Reset
            </button>
        </form>
    </div>
</template>

<script>
import { ref, onMounted, inject, watch } from 'vue';
import { UserFormGroupInput } from '@/components';
import { useI18n } from 'vue-i18n';
import {
    fetchEditUserConfig,
    checkClubExist,
    createClub,
    editUser,
} from '@/api/users';
import { useRouter } from 'vue-router';

export default {
    name: 'UserCreate',
    components: {
        UserFormGroupInput,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    setup(props, { emit }) {
        const formConfig = ref([]);
        const formData = ref({});
        const submitDisabled = ref(true);
        const { t } = useI18n();
        const router = useRouter();
        const Swal = inject('$swal');

        onMounted(async () => {
            await loadFormConfig();
            initializeFormData();
        });

        const loadFormConfig = async () => {
            try {
                formConfig.value = await fetchEditUserConfig();
            } catch (error) {
                console.error('Error fetching form configuration:', error);
            }
        };

        const initializeFormData = () => {
            formConfig.value.forEach(config => {
                if (Array.isArray(config)) {
                    config.forEach(subConfig => {
                        formData.value[subConfig.field] =
                            props.user[subConfig.field] || '';
                    });
                } else {
                    formData.value[config.field] =
                        props.user[config.field] || '';
                }
            });
        };

        // 監控 user prop 的變化，更新表單數據
        watch(() => props.user, initializeFormData);

        const handleValidation = isValid => {
            submitDisabled.value = !isValid;
        };

        const handleSubmit = async () => {
            // handle submit
            alert('submit');
        };

        const handleReset = () => {
            initializeFormData();
        };

        return {
            formConfig,
            formData,
            submitDisabled,
            handleSubmit,
            handleValidation,
            handleReset,
        };
    },
};
</script>

<style scoped>
.card {
    box-shadow: none;
}
.form-group-row {
    margin-bottom: 1rem;
    text-align: left;
}

.d-flex {
    display: flex;
    gap: 1rem;
}

.flex-fill {
    flex: 1;
}
</style>
