<template>
    <div>
        <TableComponent
            v-bind="tableProps"
            v-model="selectedUser"
            @on-refresh="fetchData"
            @on-export="exportData"
            @on-change-page="changePage"
            @on-change-page-size="changePageSize"
            @on-filter="filterData"
            @on-text-filter="textFilterData"
            @on-sort="sortData"
        >
            <template v-slot:table-actions>
                <li class="nav-item">
                    <a class="nav-link" href="#" @click="editUser">
                        <i class="fa fa-pencil-square-o"></i>
                        <p>
                            {{ $t('users.users_msg__edit') }}
                        </p>
                    </a>
                </li>
            </template>
        </TableComponent>
        <ModalComponent
            v-if="isEditModalVisible"
            id="user-edit-modal"
            :visible="isEditModalVisible"
            :title="$t('users.users_msg__edit')"
            @close="isEditModalVisible = false"
        >
            <UserEdit :user="selectedUser" />
        </ModalComponent>
    </div>
</template>

<script>
import { ref, onMounted, computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import {
    fetchUsersFromAPI,
    exportUsersFromAPI,
    fetchConfig,
} from '@/api/users';

import TableComponent from '@/components/TableComponent.vue';
import UserEdit from './UserEdit.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import { useRoute, useRouter } from 'vue-router';

export default {
    components: {
        TableComponent,
        UserEdit,
        ModalComponent,
    },
    setup() {
        const { t } = useI18n();
        const Swal = inject('$swal');
        const route = useRoute();
        const router = useRouter();

        const columnOrder = ref([]);
        const headers = ref([]);
        const data = ref([]);
        const pageSize = ref(10);
        const currentPage = ref(1);
        const totalPages = ref(1);
        const totalCounts = ref(0);
        const filter = ref('');
        const filterFields = ref([]);
        const searchFields = ref([]);
        const sortColumn = ref('');
        const sortOrder = ref('desc');
        const loading = ref(false);
        const params = ref({});

        const isEditModalVisible = ref(false);
        const selectedUser = ref([]);

        const buildParams = () => {
            const params = {
                page: currentPage.value,
                page_size: pageSize.value,
                search: filter.value,
            };
            if (sortColumn.value) {
                params.ordering = `${sortOrder.value === 'desc' ? '-' : ''}${
                    sortColumn.value
                }`;
            }
            return params;
        };
        const loadConfig = async () => {
            const response = await fetchConfig();
            const sortableColumns = response.ordering_fields;
            columnOrder.value = response.display_fields;
            headers.value = columnOrder.value.map(key => ({
                text: t(`users.users_fld__${key}`) || t(key),
                value: key,
                sortable:
                    sortableColumns.find(column => column === key) !==
                    undefined,
            }));
            const responseFilterFields = response.filter_fields;
            filterFields.value = Object.keys(responseFilterFields).map(key => ({
                key: key,
                label: t(`users.users_fld__${key}`) || key,
                type: responseFilterFields[key].type,
                value: '',
                start: '',
                end: '',
                options: responseFilterFields[key].items || [],
            }));
            if (response.search_fields) {
                searchFields.value = response.search_fields.map(key => ({
                    key: key,
                    label: t(`users.users_fld__${key}`) || key,
                }));
            }
        };
        const fetchData = async () => {
            loading.value = true;
            try {
                const response = await fetchUsersFromAPI(params.value);
                data.value = response.results;
                currentPage.value = response.current_page;
                totalCounts.value = response.total_count;
                totalPages.value = response.total_pages;
            } catch (error) {
                console.error(error);
            } finally {
                loading.value = false;
            }
        };
        const changePage = page => {
            if (page >= 1 && page <= totalPages.value) {
                params.value.page = page;
                fetchData();
            }
        };
        const changePageSize = size => {
            params.value.page_size = size;
            fetchData();
        };
        const filterData = filter => {
            if (filter.type === 'datetime') {
                params.value[`${filter.key}__gte`] = filter.start;
                params.value[`${filter.key}__lte`] = filter.end;
            }
            params.value.page = 1;
            fetchData();
        };
        const sortData = (columnIndex, order) => {
            sortColumn.value = columnIndex;
            sortOrder.value = order;

            router.replace({
                path: route.path,
                query: {
                    ...route.query,
                    sortColumn: sortColumn.value,
                    sortOrder: sortOrder.value,
                },
            });

            params.value.ordering = `${sortOrder.value === 'desc' ? '-' : ''}${
                sortColumn.value
            }`;
            params.value.page = 1;
            fetchData();
        };
        const textFilterData = search => {
            if (search.field !== '') {
                params.value.search_fields = search.field;
            } else {
                delete params.value.search_fields;
            }
            params.value.search = search.text;
            params.value.page = 1;
            sortColumn.value = '';
            sortOrder.value = 'desc';
            delete params.value.ordering;
            fetchData();
        };
        const exportData = async type => {
            const response = await exportUsersFromAPI(params.value, type);
            if (type === 'csv') {
                const blob = new Blob([response], {
                    type: 'text/csv',
                });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'users.csv';
                a.click();
                window.URL.revokeObjectURL(url);
            } else if (type === 'excel') {
                const blob = new Blob([response], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'users.xlsx';
                a.click();
                window.URL.revokeObjectURL(url);
            }
        };

        const editUser = selectedItems => {
            if (selectedItems.length === 1) {
                selectedUser.value = selectedItems[0];
                isEditModalVisible.value = true;
            } else {
                Swal.fire({
                    title: 'Warning',
                    text: 'Please select only one user to edit',
                    type: 'warning',
                    icon: 'warning',
                });
            }
        };

        const saveUser = async () => {
            alert('Save user api!');
            isEditModalVisible.value = false;
        };

        const resetModal = () => {
            selectedUser.value = null;
            isEditModalVisible.value = false;
        };

        const updateUser = user => {
            selectedUser.value = user;
        };

        const tableProps = computed(() => ({
            headers: headers.value,
            data: data.value,
            currentPage: currentPage.value,
            currentPageSize: params.value.page_size,
            totalPages: totalPages.value,
            loading: loading.value,
            sortColumn: sortColumn.value,
            sortOrder: sortOrder.value,
            filterOptions: filterFields.value,
            totalCounts: totalCounts.value,
            messageRefresh: t('users.users_msg__refresh'),
            messagePrevious: t('users.users_msg__previous'),
            messageNext: t('users.users_msg__next'),
            messageExport: t('users.users_msg__export'),
            messagePageSize: t('users.users_msg__page_size'),
            messageSearch: t('users.users_msg__search_placeholder'),
            messageSummary: 'users.users_msg__table_summary',
            pageSizes: [10, 20, 50, 100],
            searchFields: searchFields.value,
            selectable: true,
        }));

        onMounted(async () => {
            // get url params
            const sortColumnFromQuery = route.query.sortColumn || '';
            const sortOrderFromQuery = route.query.sortOrder || '';

            sortColumn.value = sortColumnFromQuery;
            sortOrder.value = sortOrderFromQuery;

            await loadConfig();

            params.value = buildParams();

            if (sortColumn.value !== '' && sortOrder.value !== '') {
                params.value.ordering = `${
                    sortOrder.value === 'desc' ? '-' : ''
                }${sortColumn.value}`;
            }

            await fetchData();
        });

        return {
            tableProps,
            fetchData,
            changePage,
            changePageSize,
            filterData,
            sortData,
            textFilterData,
            exportData,
            editUser,
            saveUser,
            resetModal,
            updateUser,
            isEditModalVisible,
            selectedUser,
        };
    },
};
</script>
