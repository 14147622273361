// store/modules/system.js
import apiClient from '@/api/index';
import { setLoading } from '@/plugins/loading';

const state = {
    logo: null,
    title: 'Default Title',
    defaultLocale: 'en',
};

const getters = {
    logo: state => state.logo,
    title: state => state.title,
    defaultLocale: state => state.defaultLocale,
};

const mutations = {
    SET_LOGO(state, logo) {
        state.logo = logo;
    },
    SET_TITLE(state, title) {
        state.title = title;
    },
    SET_DEFAULT_LANG(state, lang) {
        state.defaultLocale = lang;
    },
    CLEAR_LOGO(state) {
        state.logo = null;
    },
};
const actions = {
    async fetchTenantInfo({ commit }) {
        setLoading(true);
        try {
            const response = await apiClient.get('configs/tenant/self');
            processTenantInfo(commit, response.data);
            return response;
        } catch (error) {
            console.error('Error fetching tenant info:', error);
            throw error;
        } finally {
            setLoading(false);
        }
    },
    async fetchTenantInfoClearCache({ commit }) {
        setLoading(true);
        try {
            const response = await apiClient.get(
                'configs/tenant/self?disable_cache=true'
            );
            processTenantInfo(commit, response.data);
            return response;
        } catch (error) {
            console.error('Error fetching tenant info:', error);
            throw error;
        } finally {
            setLoading(false);
        }
    },
};

// Helper function to process the response data
function processTenantInfo(commit, data) {
    const currentLanguage =
        localStorage.getItem('locale') || data.language_code;
    commit('SET_LOGO', data.logo);
    commit('SET_DEFAULT_LANG', data.language_code);
    commit('SET_TITLE', data.title[currentLanguage]);
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
