<template>
    <div class="filter-component">
        <div class="input-group mb-3">
            <input
                type="text"
                class="form-control"
                :placeholder="messageSearch"
                v-model="textFilter"
                @keyup.enter="emitTextFilter"
            />
            <select
                v-if="searchOptions && searchOptions.length > 0"
                class="form-control"
                v-model="selectedOption"
                title="Text Search Field"
            >
                <option value="" selected="selected">
                    {{ $t('users.users_msg__search_all_fields') }}
                </option>
                <option
                    v-for="item in searchOptions"
                    :value="item.key"
                    :key="item.key"
                >
                    {{ item.label }}
                </option>
            </select>
            <button type="button" class="btn btn-primary" @click="addFilter">
                +
            </button>
        </div>
        <div
            v-for="(filter, index) in activeFilters"
            :key="index"
            class="filter-group mb-2"
        >
            <label>{{ filter.label }}</label>
            <div v-if="filter.type === 'string'">
                <input
                    type="text"
                    class="form-control"
                    v-model="filter.value"
                    @keyup.enter="updateFilter(index)"
                />
            </div>
            <div
                class="form-group d-flex flex-row align-items-center"
                v-else-if="filter.type === 'datetime'"
            >
                <input
                    title="Start Date"
                    type="datetime-local"
                    class="form-control"
                    v-model="filter.start"
                    @input="updateDateTimeFilter(index)"
                />
                <i class="fa fa-arrow-right"></i>
                <input
                    title="End Date"
                    type="datetime-local"
                    class="form-control"
                    v-model="filter.end"
                    @input="updateDateTimeFilter(index)"
                />
            </div>
            <div v-else-if="filter.type === 'select'">
                <select
                    class="form-control"
                    v-model="filter.value"
                    @change="updateFilter(index)"
                >
                    <option
                        v-for="option in filter.options"
                        :value="option[0]"
                        :key="option[0]"
                    >
                        {{ option[1] }}
                    </option>
                </select>
            </div>
            <button
                type="button"
                class="btn btn-danger"
                @click="removeFilter(index)"
            >
                -
            </button>
        </div>
    </div>
</template>

<script>
import { ref, watch } from 'vue';

export default {
    props: {
        filterOptions: {
            type: Array,
            default: () => [],
        },
        messageSearch: {
            type: String,
            default: 'Search...',
        },
        searchFields: {
            type: Array,
            default: () => [],
        },
    },
    setup(props, { emit }) {
        const textFilter = ref('');
        const selectedOption = ref('');
        const activeFilters = ref([]);
        const availableFilters = ref([...props.filterOptions]);
        const searchOptions = ref([...props.searchFields]);

        const emitTextFilter = () => {
            emit('onTextFilter', {
                text: textFilter.value,
                field: selectedOption.value,
            });
        };

        const addFilter = () => {
            if (availableFilters.value.length > 0) {
                const newFilter = availableFilters.value.shift();
                activeFilters.value.push({
                    ...newFilter,
                    value: '',
                    start: '',
                    end: '',
                });
            }
        };

        const removeFilter = index => {
            const removedFilter = activeFilters.value.splice(index, 1)[0];
            availableFilters.value.push(removedFilter);
        };

        const updateDateTimeFilter = index => {
            const filter = activeFilters.value[index];
            if (filter.start && filter.end) {
                emit('onFilter', {
                    type: filter.type,
                    key: filter.key,
                    value: filter.value,
                    start: filter.start,
                    end: filter.end,
                });
            }
        };

        const updateFilter = index => {
            const filter = activeFilters.value[index];
            emit('onFilter', {
                type: filter.type,
                key: filter.key,
                value: filter.value,
                start: filter.start,
                end: filter.end,
            });
        };

        watch(
            () => props.filterOptions,
            newOptions => {
                availableFilters.value = [...newOptions];
            }
        );

        watch(
            () => props.searchFields,
            newSearchFields => {
                searchOptions.value = [...newSearchFields];
            }
        );
        return {
            textFilter,
            selectedOption,
            searchOptions,
            activeFilters,
            availableFilters,
            emitTextFilter,
            addFilter,
            removeFilter,
            updateDateTimeFilter,
            updateFilter,
        };
    },
};
</script>

<style scoped>
.filter-group {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
}

.filter-group label {
    flex: 0 0 100px;
}

.form-control {
    margin: 15px 3px;
}

.filter-group .form-control {
    flex: 1;
}
</style>
