<template>
    <nav class="navbar navbar-expand-lg">
        <div class="container-fluid">
            <div class="left-section">
                <button
                    class="btn btn-icon btn-round navbar-burger"
                    @click="$emit('toggle-sidebar')"
                >
                    <i class="fa fa-bars"></i>
                </button>
            </div>
            <div class="center-section">
                <label class="navbar-brand">
                    {{ systemTitle }} / {{ pageTitle }}
                </label>
            </div>
            <div class="right-section">
                <div class="language-selector">
                    <select
                        v-model="currentLanguage"
                        @change="changeLanguage(currentLanguage)"
                    >
                        <option
                            v-for="language in languages"
                            :key="language.code"
                            :value="language.code"
                        >
                            {{ language.name }}
                        </option>
                    </select>
                </div>
                <div class="user-menu-wrapper">
                    <button
                        ref="toggleButtonRef"
                        class="btn btn-icon btn-round"
                        @click="toggleUserMenu"
                    >
                        <i class="fa fa-user"></i>
                    </button>
                    <div
                        ref="userMenuRef"
                        v-show="isUserMenuOpen"
                        class="user-menu-dropdown"
                    >
                        <div class="user-info">
                            <p>{{ welcomeMessage }}</p>
                            <p>
                                {{
                                    `${$t('users.users_fld__name')}: ${
                                        userSelf.username
                                    }`
                                }}
                            </p>
                            <p>
                                {{
                                    `${$t('users.users_fld__club_name')}: ${
                                        userSelf.club_name
                                    }`
                                }}
                            </p>
                        </div>
                        <div class="menu-actions nav-items">
                            <button
                                class="btn btn-link"
                                @click="changePassword"
                            >
                                <i class="fa fa-key"></i>
                                {{ $t('sidebar.sidebar_msg__change_password') }}
                            </button>
                            <button class="btn btn-link" @click="logout">
                                <i class="fa fa-sign-out"></i>
                                {{ $t('login.login_msg__sign_out') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { useLocale } from '@/composables/useLocale';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default {
    name: 'NavbarComponent',
    emits: ['toggle-sidebar'],
    props: {
        systemTitle: {
            type: String,
            default: 'Club Assist',
        },
        pageTitle: {
            type: String,
            default: '',
        },
        userSelf: {
            type: Object,
            default: () => ({
                username: '',
                club_name: '',
            }),
        },
        welcomeMessage: {
            type: String,
            default: 'Welcome!',
        },
    },
    setup() {
        const { currentLanguage, languages, changeLanguage, fetchLanguages } =
            useLocale();
        const router = useRouter();
        const store = useStore();

        const isUserMenuOpen = ref(false);

        const userMenuRef = ref(null);
        const toggleButtonRef = ref(null);

        const toggleUserMenu = () => {
            isUserMenuOpen.value = !isUserMenuOpen.value;
        };
        const changePassword = () => {
            router.push('/users/change-password');
        };

        const logout = () => {
            store.dispatch('auth/signout');
            router.push('/');
        };

        const handleClickOutside = event => {
            if (isUserMenuOpen.value) {
                if (
                    userMenuRef.value &&
                    !userMenuRef.value.contains(event.target) &&
                    toggleButtonRef.value &&
                    !toggleButtonRef.value.contains(event.target)
                ) {
                    isUserMenuOpen.value = false;
                }
            }
        };

        onMounted(() => {
            fetchLanguages();
            document.addEventListener('click', handleClickOutside);
        });

        onUnmounted(() => {
            document.removeEventListener('click', handleClickOutside);
        });

        return {
            currentLanguage,
            languages,
            changeLanguage,
            isUserMenuOpen,
            toggleUserMenu,
            changePassword,
            logout,
            userMenuRef,
            toggleButtonRef,
        };
    },
};
</script>

<style scoped>
.navbar {
    width: 100%;
    /* height: 60px; */
    background-color: #333;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}
input[type='checkbox']:checked ~ #sidebarMenu {
    transform: translateX(0);
}

input[type='checkbox'] {
    transition: all 0.3s;
    box-sizing: border-box;
    display: none;
}
.sidebarIconToggle {
    transition: all 0.3s;
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    z-index: 99;
    height: 100%;
    width: 100%;
    top: 22px;
    left: 15px;
    height: 22px;
    width: 22px;
}
.spinner {
    transition: all 0.3s;
    box-sizing: border-box;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #fff;
}
.horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}
.diagonal.part-1 {
    position: relative;
    transition: all 0.3s;
    box-sizing: border-box;
    float: left;
}
.diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}
input[type='checkbox']:checked ~ .sidebarIconToggle > .horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    opacity: 0;
}
input[type='checkbox']:checked ~ .sidebarIconToggle > .diagonal.part-1 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(135deg);
    margin-top: 8px;
}
input[type='checkbox']:checked ~ .sidebarIconToggle > .diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(-135deg);
    margin-top: -9px;
}
.left-section,
.center-section,
.right-section {
    display: flex;
    align-items: center;
}

.right-section {
    display: flex;
    align-items: center;
}

.language-selector {
    margin-right: 15px;
}

.language-selector select {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
    font-size: 14px;
    outline: none;
    transition: border-color 0.3s ease;
}

.language-selector select:focus {
    border-color: #007bff;
}

.language-selector select option {
    background-color: #fff;
    color: #333;
}
.user-menu-wrapper {
    position: relative;
}

.user-menu-dropdown {
    position: absolute;
    right: 0;
    top: 100%;
    background-color: #fff;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    width: 250px;
    z-index: 1000;
}

.user-info p {
    margin: 0;
    padding: 5px 0;
    font-weight: bold;
    word-wrap: break-word;
}

.menu-actions {
    margin-top: 10px;
}

.menu-actions .btn-link {
    display: block;
    width: 100%;
    text-align: left;
    padding: 5px 0;
    color: #7a9e9f;
    text-decoration: none;
}

.menu-actions .btn-link:hover {
    text-decoration: none;
}
</style>
