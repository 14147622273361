<template>
    <div class="d-flex align-items-center justify-content-center vh-100">
        <PasswordForm
            :formTitle="$t('users.users_title__reset_password')"
            :buttonText="$t('users.users_button__reset_password')"
            @submit="handleResetPassword"
        />
    </div>
</template>

<script>
import { PasswordForm } from '@/components';
import { inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { resetPassword } from '@/api/users';
import { useRouter } from 'vue-router';

export default {
    props: {
        token: {
            type: String,
            required: true,
        },
    },
    components: {
        PasswordForm,
    },
    setup(props) {
        const { t } = useI18n();
        const router = useRouter();
        const Swal = inject('$swal');

        const handleResetPassword = async formData => {
            if (formData instanceof Event) {
                return;
            }

            try {
                const response = await resetPassword(
                    props.token,
                    formData.newPassword
                );
                await Swal.fire({
                    title: 'Success',
                    text: t(`users.${response.data.message}`),
                    icon: 'success',
                    showConfirmButton: true,
                });
                router.push({ path: '/' });
            } catch (error) {
                await Swal.fire({
                    title: 'Error',
                    text: t(`users.${error.response.data.error}`),
                    icon: 'error',
                    showConfirmButton: true,
                });
            }
        };

        return {
            handleResetPassword,
        };
    },
};
</script>
