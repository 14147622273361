// src/store/modules/i18n.js
import apiClient from '@/api/index';

const state = {
    locale: localStorage.getItem('locale') || 'en',
    messages: {},
};

const mutations = {
    setLocale(state, { locale, messages }) {
        state.locale = locale;
        state.messages = { ...state.messages, [locale]: messages };
        localStorage.setItem('locale', locale);
    },
};

const actions = {
    async loadLocale({ commit }, locale) {
        const response = await apiClient.get(
            `/languages/lang/${locale}?disable_cache=false`
        );
        commit('setLocale', { locale, messages: response.data });
    },
};

const getters = {
    locale: state => state.locale,
    messages: state => state.messages,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
